import * as React from 'react'
import Seo from "../components/seo"
import Layout from "../components/layout"
import VolunteerSignup from "../components/volunteerSignup"

const volunteerPage = () => {
    return (
        <Layout>
            <Seo title="Volunteer" />
            <div className="container">
                <h1 className="text-center">Volunteer</h1>
                <p className="max-w-2xl m-auto text-center relative z-20 mt-20">Does this sound like what you’d like to get involved with? Register a location if you are an organisation to setup a new volunteer hotspot or just volunteer to join an established location.</p>
                <VolunteerSignup />
            </div>
        </Layout>
    )
}

export default volunteerPage