import * as React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import Cta from './cta'

const VolunteerSignup = () => {
    const ctaConfig = [
        { title : 'Register to Volunteer', to : 'https://form.jotform.co/80942866290869' },
        { title : 'Register new Location', to : 'https://form.jotform.com/202841138358860' }
    ]
    
    return (
        <div id="volunteer" className="mt-20 m-auto max-w-7xl text-center relative">
            <StaticImage
                src="../images/volunteers.png"
                width={800}
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                placeholder="blurred"
                alt="Group of volunteers posing for photo"
                class="-mt-20 z-0 relative"
            />
            <h2>Volunteer for Community Cuts</h2>
            <Cta config={ctaConfig} />
        </div>
    )
}

export default VolunteerSignup